<template>
  <div class="cont">
    <Breadcrumb :crumbs="crumbs"/>
    <el-row type="flex" style="align-items:center;margin-bottom:30px;">
      <el-radio-group v-model="routeState">
        <el-radio-button label="proceeds_summary">收款汇总</el-radio-button>
        <el-radio-button label="proceeds_records">收款记录</el-radio-button>
      </el-radio-group>
      <p class="records-hint" v-show="routeState !== 'proceeds_summary'">
        <i class="el-icon-warning"></i>
        收款记录不含记一笔收款
      </p>
    </el-row>
    <div>
      <router-view name="proceedsSummary" ref="viewPage"></router-view>
    </div>
  </div>
</template>
<script>
export default {
  watch: {
    routeState(newVal,oldVal){
      this.$router.push({path: `/pms/proceeds_summarys/${newVal}`})
    },
    $route:{
        handler(val) {
          this.routeState = val.fullPath.split("/")[3];
        },
        immediate:true,
        deep: true
    }
  },
  data() {
    return {
      crumbs: new Map([
        ['PMS'], ['统计中心'], ['收款汇总']
      ]),
      routeState:"proceeds_summary"
    }
  }
}
</script>
<style scoped lang="scss">
.records-hint{
  margin-left:20px;
  color:#ff8f02;
}
</style>
